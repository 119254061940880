import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";
import debounce from "@material-ui/core/utils/debounce";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  type: string;
  attributes: IChatAttributes | IGroupChatAttributes;
}

export interface IChatAttributes {
  id: number;
  account_id: number;
  sender_name: string;
  receiver_id: number;
  receiver_name: string;
  created_at: string;
  unread_count: number;
  last_message: string | null;
  last_message_time: number;
  sender_chat_url: string;
  receiver_chat_url: string;
  photo: string | null;
  email: string | null;
  bio: string | null;
  mutual_groups: [];
  blocked_user: boolean;
}

export interface IGroupChatAttributes {
  id: number;
  name: string;
  description: string;
  created_at: string;
  group_members_count: number;
  group_chat_url: string;
  last_message_data: null | LastMessage;
  group_image: string | null;
  current_user_id: number;
  current_user_name: string;
  group_members: GroupMembersData;
}

export interface GroupMembersData {
  data: GroupMember[];
}

export interface GroupMember {
  id: string;
  type: string;
  attributes: GroupMemberAttributes;
}

export interface GroupMemberAttributes {
  id: number;
  group_chat_id: number;
  admin: boolean;
  account_id: number;
  created_at: string;
  name: string;
  photo: string;
}

export interface LastMessage {
  text: string;
  name: string;
  unread_count: number;
  time: number;
}

export interface ISearchFriendsList {
  id: string | null;
  type: string | null;
  attributes: {
    current_user_id: number | null;
    current_user_name: string | null;
    id: number | null;
    status?: string | null;
    created_at: string | null;
    updated_at?: string | null;
    receiver_id?: number | null;
    name: string | null;
    other_user_detail?: string | null;
    photo?: string | null;
    chat_url: string | undefined;
    group_members_count?: number | null;
    group_chat_url?: string | null;
    group_image?: string | null;
    group_members?: {
      data: Array<{
        id: string;
        type: string;
        attributes: {
          id: number;
          group_chat_id: number;
          admin: boolean;
          account_id: number;
          created_at: string;
          name: string;
          photo?: string | null;
        };
      }>;
    };
    last_message_data?: {
      text: string;
      name: string;
      unread_count: number;
      time: number;
    };
  };
}

export interface GroupFormData {
  selectedAvatarImage: string,
  groupName: string,
  groupDescription: string,
  groupMembers: number[],
}

export interface ISearchFriendsListForGroup {
  id: string;
  type: string;
  attributes: {
    current_user_name: string;
    current_user_id: number;
    id: number;
    created_at: string;
    updated_at: string;
    name: string;
    status: "un_friend" | string;
    other_user_detail: string | null;
    mutual_friends_count: string;
    receipient_id: number;
    photo: string;
    chat_url: string;
  }
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  groupList: IChat[];
  isVisibleModal: boolean;
  selectedOption: string;
  senderChatUrl: string | undefined;
  selectedChatId: string | null;
  receiverId: number | null;
  receiverPhoto: string | null;
  receiverName: string | null;
  senderName: string | null;
  unreadCountOfMessages: number | null;
  lastMessage: string | null;
  createChatModalOpen: boolean;
  searchFriendsList: ISearchFriendsList[];
  searchFriendInput: string;
  chatType: "private" | "group",
  groupMembersCount: number,
  isCreateGroupViewOpen: boolean,
  groupFormData: GroupFormData;
  searchFriendsListForGroup: ISearchFriendsListForGroup[];
  searchFriendForGroupInput: string;
  isToastOpen: boolean;
  chatToastMsg: string;
  viewContact:boolean;
  isGroupInfoVisible: boolean;
  groupDescription: string;
  selectedGroupMember: GroupMember[];
  receiverBio: string | null;
  receiverEmail: string | null;
  mutualGroups: [];
  isBlocked: boolean;
  isToastError: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  getGroupListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  getSearchFriendsListApiCallId: string = "";
  getSearchFriendsForGroupListApiCallId: string = "";
  createNewGroupApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      groupList: [],
      isVisibleModal: false,
      selectedOption: 'all',
      senderChatUrl: '',
      selectedChatId: null,
      receiverId: null,
      receiverPhoto: null,
      senderName: null,
      receiverName: null,
      unreadCountOfMessages: null,
      lastMessage: null,
      createChatModalOpen: false,
      searchFriendsList: [],
      searchFriendInput: "",
      chatType: "private",
      groupMembersCount: 0,
      isCreateGroupViewOpen: false,
      groupFormData: {
        selectedAvatarImage: '',
        groupName: '',
        groupDescription: '',
        groupMembers: [],
      },
      searchFriendsListForGroup: [],
      searchFriendForGroupInput: '',
      isToastOpen: false,
      chatToastMsg: "",
      isGroupInfoVisible: false,
      viewContact:false,
      groupDescription: "",
      selectedGroupMember: [],
      receiverBio: null,
      receiverEmail: null,
      mutualGroups: [],
      isBlocked: false,
      isToastError: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    const profileId = await getStorageData("profileId");
    const profileName = await getStorageData("profileName");
    const activeChatUrl = await getStorageData("activeChatUrl");
    const activeChatName = await getStorageData("activeChatName");
    const activeChatPhoto = await getStorageData("activeChatPhoto");
    const activeChatId = await getStorageData("activeChatId");
    this.setState(() => ({
      accountId: profileId,
      senderChatUrl: activeChatUrl,
      receiverName: activeChatName,
      receiverPhoto: activeChatPhoto,
      receiverId: activeChatId,
      senderName: profileName,
    }));
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (this.state.receiverName !== prevState.receiverName) {
      await removeStorageData("profileId");
      await removeStorageData("profileName");
      await removeStorageData("activeChatUrl");
      await removeStorageData("activeChatName");
      await removeStorageData("activeChatPhoto");
    }
  }
  

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  handleOptionChange = async (event: React.MouseEvent<HTMLElement>, newOption: string) => {
    if (newOption !== null) {
      this.setState({ selectedOption: newOption }, () => {
        if (newOption === "group") {
          this.getGroupList();
        } else {
          this.getChatList();
        }
      });
    }
  };

  handleGroupFormChange = (fieldName: string, value: string) => {
    this.setState((prevState) => ({
      groupFormData: {
        ...prevState.groupFormData,
        [fieldName]: value
      }
    }));
  };

  isStringNullOrBlank = (string: string) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ViewChat");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getChatList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    const privateOption = this.state.selectedOption === "private" ? "?private=true" : "?private=false";

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsApiEndpoint + privateOption
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getGroupList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGroupsListApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createNewGroup = () => {
    if (this.state.groupFormData.selectedAvatarImage !== "") {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNewGroupApiCallId = requestMessage.messageId;

    const bodyData = {
      group: {
        name: this.state.groupFormData.groupName,
        description: this.state.groupFormData.groupDescription,
        group_image: {
          data: this.state.groupFormData.selectedAvatarImage
        },
        group_chat_members_attributes: this.state.groupFormData.groupMembers,
      },
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewGroup
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  else {
    this.setState({isToastError: true, chatToastMsg: "Please upload the image"})
  }
}

  handleGetSearchFriendsForGroupList = (search?: string | number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchFriendsForGroupListApiCallId = requestMessage.messageId;

    const searchQuery = search ? `?search=${search}` : '?search=';

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSearchFriendsListForGroupApiEndPoint + searchQuery
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSearchFriendsForGroupInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    this.setState({ searchFriendForGroupInput: searchValue });
  
    this.debouncedSearchForGroup(searchValue);
  };

  debouncedSearchForGroup = debounce((searchValue: string) => {
    this.handleGetSearchFriendsForGroupList(searchValue);
  }, 300);

  addMembersToTheGroup = (member: number) => {
    this.setState((prevState) => {
      const { groupMembers } = prevState.groupFormData;
      const isMemberIncluded = groupMembers.includes(member);

      return {
        groupFormData: {
          ...prevState.groupFormData,
          groupMembers: isMemberIncluded
            ? groupMembers.filter((id) => id !== member)
            : [...groupMembers, member],
        },
      };
    });
  };

  handleCreateGroupChat = () => {
    this.createNewGroup();
  }

  updateChatList = () => {
    this.getChatList();
  };

  handleCloseChatToast = () => {
    this.setState({isToastOpen: false, isToastError: false, chatToastMsg: ""})
  }

  handleChatSelection = (chat: IChat, isViewGroup: boolean= false) => {
    if (chat.type === 'chat_room') {
      const privateChatAttributes = chat.attributes as IChatAttributes;

      this.setState({
        senderChatUrl: privateChatAttributes.sender_chat_url,
        selectedChatId: chat.id,
        receiverId: privateChatAttributes.receiver_id,
        accountId: privateChatAttributes.account_id,
        receiverPhoto: privateChatAttributes.photo,
        senderName: privateChatAttributes.sender_name,
        receiverName: privateChatAttributes.receiver_name,
        unreadCountOfMessages: privateChatAttributes.unread_count,
        lastMessage: privateChatAttributes.last_message,
        chatType: "private",
        isCreateGroupViewOpen: false,
        viewContact:false,
        isGroupInfoVisible: isViewGroup,
        receiverBio: privateChatAttributes.bio,
        receiverEmail: privateChatAttributes.email,
        mutualGroups: privateChatAttributes.mutual_groups,
        isBlocked: privateChatAttributes.blocked_user
      });
    } else {
      const groupChatAttributes = chat.attributes as IGroupChatAttributes;
      const lastMessage = groupChatAttributes.last_message_data;
  
      this.setState({
        senderChatUrl: groupChatAttributes.group_chat_url,
        selectedChatId: chat.id,
        receiverId: groupChatAttributes.id,
        accountId: groupChatAttributes.current_user_id,
        receiverPhoto: groupChatAttributes.group_image,
        senderName: groupChatAttributes.current_user_name,
        receiverName: groupChatAttributes.name,
        unreadCountOfMessages: lastMessage ? lastMessage.unread_count : 0,
        lastMessage: lastMessage ? lastMessage.text : null,
        chatType: "group",
        groupMembersCount: groupChatAttributes.group_members_count,
        isCreateGroupViewOpen: false,
        groupDescription: groupChatAttributes.description,
        selectedGroupMember: groupChatAttributes.group_members.data,
        viewContact:false,
        isGroupInfoVisible: isViewGroup,
        receiverBio: "",
        receiverEmail: "",
        isBlocked: false,
      });
    }
  };

  handleCloseCreateGroupView = () => {
    this.setState({isCreateGroupViewOpen: false})
  }

  createChatRoom = (chatName: string) => {
    if (this.isStringNullOrBlank(chatName)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
        "Access-Control-Allow-Origin": "*",
      };
      const bodyData = {
        name: chatName,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createChatRoomApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  showCreateChatModal = () => {
    this.setState({createChatModalOpen: true})
    this.handleGetSearchFriendsList();
  }

  handleCloseCreateChatModal = () => {
    this.setState({createChatModalOpen: false})
  }

  handleSendCreateChatModal = () => {
    this.setState({createChatModalOpen: false})
  }

  handleOpenCreateGroupView = () => {
    this.handleGetSearchFriendsForGroupList();

    this.setState({
      isCreateGroupViewOpen: true, 
      createChatModalOpen: false,
      groupFormData: {
        selectedAvatarImage: '',
        groupName: '',
        groupDescription: '',
        groupMembers: [],
      }
    })
  }

  inputRoomNameProps = {
    onChangeText: (chatName: string) => {
      this.setState({ chatName });
    },
  };

  btnAddRoomProps = {
    onPress: () => this.createChatRoom(this.state.chatName),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => {
      this.showModal();
    },
  };

  getLastTimeMessage = (createdAt: number) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    
    const differenceInMilliseconds = currentDate.getTime() - createdDate.getTime();
    
    const minutes = Math.floor(differenceInMilliseconds / (1000 * 60));
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24 * 7));
    const months = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30));
    const years = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24 * 365));

    if (years > 0) {
      return `${years}y`;
    } else if (months > 0) {
      return `${months}m`;
    } else if (weeks > 0) {
      return `${weeks}w`;
    } else if (days > 0) {
      return `${days}d`;
    } else if (hours > 0) {
      return `${hours}h`;
    } else if (minutes > 0) {
      return `${minutes}m`;
    }
  };

  handleGetSearchFriendsList = (search?: string | number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchFriendsListApiCallId = requestMessage.messageId;

    const searchQuery = search ? `?search=${search}` : '?search=';

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSearchFriendsListApiEndPoint + searchQuery
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSearchListApi = (responseJson: any) => {
      this.setState({
        searchFriendsList: responseJson.data,
      });
  };

  getSearchFriendsForGroupListApi = (responseJson: any) => {
    this.setState({
      searchFriendsListForGroup: responseJson.data,
    });
  };

  getChatListApi = (responseJson: any) => {
    this.setState({
      chatList: responseJson.data,
    });
    if(this.state.selectedChatId === null && this.state.receiverName === null) this.handleChatSelection(responseJson.data[0])
  };

  getGroupListApi = (responseJson: any) => {
    this.setState({
      groupList: responseJson.data,
    });
  }; 

  handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    this.setState({ searchFriendInput: searchValue });
  
    this.debouncedSearch(searchValue);
  };

  handleMessageButton = (friend: ISearchFriendsList) => {
    const { type, attributes } = friend;
    
    if (type === "group_chat_list") {
      const {
        current_user_id,
        current_user_name,
        group_chat_url,
        name,
        group_image,
        id
      } = attributes;
  
      this.setState({
        accountId: current_user_id || -1,
        senderName: current_user_name,
        senderChatUrl: group_chat_url || null || undefined,
        receiverName: name,
        receiverPhoto: group_image || null,
        receiverId: id,
        chatType: 'group',
        isCreateGroupViewOpen: false,
      });
      
    } else if (type === "connection_chat") {
      const {
        current_user_id,
        current_user_name,
        chat_url,
        name,
        receiver_id,
        photo,
      } = attributes;
  
      this.setState({
        accountId: current_user_id || -1,
        senderName: current_user_name,
        senderChatUrl: chat_url,
        receiverName: name,
        receiverPhoto: photo || null,
        receiverId: receiver_id || null,
        chatType: 'private',
        isCreateGroupViewOpen: false,
      });
    }
  
    this.handleCloseCreateChatModal();
  };
  
  debouncedSearch = debounce((searchValue: string) => {
    this.handleGetSearchFriendsList(searchValue);
  }, 300);

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);

    const token = await getStorageData("token");
    if (token) this.setState({ token });

    this.handleSessionResponse(message.id, token);

    this.handleApiCalls(message.id, apiRequestCallId, responseJson);
  }

  handleSessionResponse(messageId: string, token: string | null) {
    if (getName(MessageEnum.SessionResponseMessage) === messageId) {
      runEngine.debugLog("TOKEN", token);
      if (token) {
        this.setState({ token }, () => this.getChatList());
      }
    }
  }

  handleApiCalls(messageId: string, apiRequestCallId: string, responseJson: any) {
    if (this.isMatchingApiCall(messageId, apiRequestCallId, responseJson, this.getChatListApiCallId)) {
      this.getChatListApiCallId = "";
      this.getChatListApi(responseJson);
    }

    if (this.isMatchingApiCall(messageId, apiRequestCallId, responseJson, this.getGroupListApiCallId)) {
      this.getGroupListApiCallId = "";
      this.getGroupListApi(responseJson);
    }

    if (this.isCreateChatRoomApiCall(messageId, apiRequestCallId, responseJson)) {
      this.createChatRoomApiCallId = "";
      this.hideModal();
      this.getChatList();
    }

    if (this.isMatchingApiCall(messageId, apiRequestCallId, responseJson, this.getSearchFriendsListApiCallId)) {
      this.getSearchFriendsListApiCallId = "";
      this.getSearchListApi(responseJson);
    }

    if (this.isMatchingApiCall(messageId, apiRequestCallId, responseJson, this.getSearchFriendsForGroupListApiCallId)) {
      this.getSearchFriendsForGroupListApiCallId = "";
      this.getSearchFriendsForGroupListApi(responseJson);
    }

    if (this.isMatchingApiCall(messageId, apiRequestCallId, responseJson, this.createNewGroupApiCallId)) {
      this.createNewGroupApiCallId = "";
      this.getChatList();
      this.handleCloseCreateGroupView();
      this.setState({isToastOpen: true, chatToastMsg: "Group successfully created"})
    }
  }

  isMatchingApiCall(messageId: string, apiRequestCallId: string, responseJson: any, apiCallId: string): boolean {
    return (
      responseJson?.data &&
      getName(MessageEnum.RestAPIResponceMessage) === messageId &&
      apiCallId.length > 0 &&
      apiRequestCallId === apiCallId
    );
  }

  isCreateChatRoomApiCall(messageId: string, apiRequestCallId: string, responseJson: any): boolean {
    return (
      getName(MessageEnum.RestAPIResponceMessage) === messageId &&
      this.createChatRoomApiCallId.length > 0 &&
      apiRequestCallId === this.createChatRoomApiCallId &&
      responseJson
    );
  }
  
  handleViewContact=()=>{
    this.setState({viewContact:!this.state.viewContact})
  }

  handleViewGroup=()=>{
    this.setState({isGroupInfoVisible: !this.state.isGroupInfoVisible});
    this.handleGetSearchFriendsForGroupList();
  }
  
  // Customizable Area End
}
