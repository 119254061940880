import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { styled } from "@material-ui/core";
import customTheme from "./CustomTheme.web";

interface CustomMenuProps {
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  options: { label: string; action: () => void }[];
  position: "left" | "right";
  fontSize?: number;
  width?: number | string;
  isGroupInfo?: boolean;
}

const StyledMenu = styled(Menu)(({isGroupInfo}: any) =>({
  "& .MuiPaper-root": {
    backgroundColor: customTheme.palette.white.primary,
    borderRadius: 16,
    padding: "8px 16px",
    maxHeight: "50vh",
    overflowY: "auto",
    marginTop: isGroupInfo ? '15px' : '0px'
  },
  "& .MuiMenu-list": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  "& .MuiMenuItem-root": {
    justifyContent: "flex-start",
    padding: "12px 16px",
  },
  "& .MuiMenuItem-root:hover": {
    borderRadius: 16,
  },
}));

const StyledMenuItem = styled(MenuItem)(({ fontSize, width }: any) => ({
  fontSize: `${fontSize}px !important`,
  fontFamily: `Open Sans !important`,
  width,
  "&:hover": {
    backgroundColor: `${customTheme.palette.grey.primary} !important`,
    borderRadius: 24,
  },
}));

const CustomMenu: React.FC<CustomMenuProps> = ({
  open,
  onClose,
  anchorEl,
  options,
  position,
  fontSize = 16,
  width = 223,
  isGroupInfo = false
}) => {
  return (
    <div>
      <StyledMenu
        className="top-right-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: position,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: position,
        }}
        isGroupInfo={isGroupInfo}
      >
        {options.map((option, index) => (
          <StyledMenuItem
            key={index}
            onClick={option.action}
            fontSize={fontSize}
            width={width}
          >
            {option.label}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

export default CustomMenu;
