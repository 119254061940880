import React from "react";

// Customizable Area Start
import Header from "../../../blocks/landingpage/src/Header.web";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Grid,
  Typography,
  styled,
} from "@material-ui/core";
import customTheme from "../../../components/src/CustomTheme.web";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
import Carousel from "react-material-ui-carousel";
import {
  GoogleMap,
  LoadScript,
  Marker,
  OverlayView,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import {
  add,
  checkbox,
  disabledRsvp,
  drop,
  flag,
  privateIcon,
  publicIcon,
  rsvp,
  search,
  shareAction,
  uncheckbox,
  uploadBrowse,
  deleteIcon,
  back,
  markerIcon,
  calenderIcon,
} from "./assets";
import DatePicker from "react-datepicker";
import Likeapost2 from "../../../blocks/likeapost2/src/Likeapost2.web";
import Comments from "../../../blocks/comments/src/Comments.web";
import ContentFlag from "../../../blocks/contentflag/src/ContentFlag.web";
import Share from "../../../blocks/share/src/Share.web";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// Customizable Area End

import NewsFeedController, { Props } from "./NewsFeedController";

export default class NewsFeed extends NewsFeedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderFilterSection(
    heading: string,
    items: any,
    showCount: number,
    showMoreHandler: () => void,
    isAllShown: boolean
  ) {
    return (
      <>
        <HeadingFilterN>{heading}</HeadingFilterN>
        <div style={{ height: 170, overflow: "auto" }}>
          {items.slice(0, showCount).map((item: any, index: any) => (
            <LocationListingN key={index}>
              <Checkbox
                disableFocusRipple
                disableRipple
                icon={<img src={uncheckbox} alt="uncheckbox" />}
                checkedIcon={
                  <img
                    src={checkbox}
                    alt="checkbox"
                    style={{ width: "20px", height: "20px" }}
                  />
                }
                checked={this.state.selectedItems.includes(item.name)}
                onChange={() => this.handleCheckboxChange(item.name)}
                data-test-id="handleCheckboxChange"
              />
              <CheckboxTitleN>{item.name}</CheckboxTitleN>
            </LocationListingN>
          ))}
        </div>
        <this.ShowMoreButton
          disabled={isAllShown}
          onClick={showMoreHandler}
          data-test-id="showMoreHandler"
        >
          show more
        </this.ShowMoreButton>
      </>
    );
  }

  renderEventCard(item: any, index: number) {
    return (
      <EventCartN key={index}>
        <HomeListMainN>
          <div
            style={webStyle.flexPointer}
            onClick={() => this.handleViewEvent(item.id)}
            data-test-id="handleViewEvent"
          >
            <HomeListImgN src={item.attributes.profile_image} />
            <HomeListInnerN>
              <HomeListNameN>
                {item.attributes.name}
                <img
                  src={
                    item.attributes.is_public === "is_public"
                      ? publicIcon
                      : privateIcon
                  }
                  alt="tag"
                />
              </HomeListNameN>
              <HomeListMutualN>{item.attributes.event_date}</HomeListMutualN>
            </HomeListInnerN>
          </div>
          <img
            style={{
              cursor:
                item.attributes.is_public === "is_public" ? "pointer" : "",
            }}
            src={
              item.attributes.is_public === "is_public" ? rsvp : disabledRsvp
            }
            alt="RSVP button"
          />
        </HomeListMainN>
        <DividerN />
        <div style={{ padding: "36px 24px 24px 24px" }}>
          <TitleN>{item.attributes.event_name}</TitleN>
          <FriendProfileMutualN>
            {item.attributes.keywords}
          </FriendProfileMutualN>
          <DescriptionN>{item.attributes.description}</DescriptionN>
          <ActionButtonN>
            <Likeapost2
              navigation={this.props.navigation}
              id={""}
              eventId={item.id}
              likeCount={item.attributes.like}
              currentUserLike={item.attributes.current_user_like}
              getAllDataCallBack={this.getEvents}
            />
            <Comments
              navigation={this.props.navigation}
              id={""}
              eventId={item.id}
              CommentCount={item.attributes.comments}
              getAllDataCallBack={this.getEvents}
              flagCategory={this.state.flagCategoriesReason}
            />
            <Share
              navigation={this.props.navigation}
              id={""}
              shareIcon={shareAction}
              eventId={item.id}
            />
            <ContentFlag
              flagIcon={flag}
              flagContent={this.state.flagCategoriesReason}
              contentId={item.id}
              flagFor="Event"
            />
          </ActionButtonN>
        </div>
      </EventCartN>
    );
  }

  renderKeywords() {
    const { keyword } = this.state;
    return (
      <div style={{ marginTop: 30 }}>
        <LabelN>Add Keywords</LabelN>
        <InputN
          type="text"
          value={keyword}
          onChange={this.handleTagChange}
          placeholder="Keyword"
          data-test-id="handleTagChange"
        />
      </div>
    );
  }

  renderCreateEventModal() {
    const {
      openEventModal,
      event,
      eventError,
      description,
      descriptionError,
      descriptionWordCount,
      date,
      time,
      timeError,
      dateError,
      files,
      uploadError,
      eventAddressError,
      commonError,
      category,
      categoryError,
      openCategoryMenu,
    } = this.state;

    return (
      <>
        {openEventModal && (
          <ParentModalN>
            <ChildModalN>
              <EventTitleMainN>Create Event</EventTitleMainN>
              {commonError && (
                <TermErrorN>
                  <ErrorTypoN>{commonError}</ErrorTypoN>
                </TermErrorN>
              )}
              <div style={{ margin: "40px 0px" }}>
                <LabelN>Event Name</LabelN>
                <InputN
                  type="text"
                  placeholder="Title"
                  value={event}
                  onChange={this.handleEventChange}
                  data-test-id="handleEventChange"
                  isError={eventError}
                />
                {eventError && <ErrorMsgN>{eventError}</ErrorMsgN>}
              </div>
              <div style={{ margin: "40px 0px" }}>
                <LabelN>Description</LabelN>
                <TextAreaN
                  type="text"
                  placeholder="Description..."
                  value={description}
                  onChange={this.handleDescriptionChange}
                  data-test-id="handleDescriptionChange"
                  isError={descriptionError}
                />
                <WordCountN descriptionError={descriptionError}>
                  {descriptionError && (
                    <ErrorMsgN>{descriptionError}</ErrorMsgN>
                  )}
                  {descriptionWordCount}/100
                </WordCountN>
                <div style={{ marginTop: 24 }}>
                  <LabelN>Address</LabelN>
                  <MainDivN>
                    <StandaloneSearchBox
                      onLoad={(ref) => (this.inputRef = ref)}
                      onPlacesChanged={this.handlePlaceChanged}
                      data-test-id="handlePlaceChanged"
                    >
                      <InputN
                        onChange={this.handleInputChange}
                        data-test-id="handleInputChange"
                        type="text"
                        placeholder="Address"
                        isError={eventAddressError}
                      />
                    </StandaloneSearchBox>
                    {eventAddressError && (
                      <ErrorMsgN>{eventAddressError}</ErrorMsgN>
                    )}
                  </MainDivN>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: 4,
                    flexDirection: "column",
                    marginTop: 24,
                  }}
                >
                  <LabelN>Category</LabelN>
                  <CustomFlagDropdownN isOpen={openCategoryMenu}>
                    <input
                      type="text"
                      placeholder="Select category"
                      value={category}
                      onClick={this.handleMenuItem}
                      data-test-id="handleMenuItem"
                      style={{
                        borderColor: categoryError
                          ? customTheme.palette.red.secondary
                          : customTheme.palette.grey.light,
                        height: 56,
                      }}
                    />
                    {categoryError && (
                      <ErrorMsgN color="error">{categoryError}</ErrorMsgN>
                    )}
                    <KeyboardArrowDownIcon className="downIconN" />
                    <Box className="flagItemN">
                      {this.state.categories.map((item: any, index: any) => (
                        <TypographyItemN
                          key={index}
                          onClick={() =>
                            this.handleSelectReason(item.id, item.name)
                          }
                          data-test-id="handleSelectReason"
                        >
                          {item.name}
                        </TypographyItemN>
                      ))}
                    </Box>
                  </CustomFlagDropdownN>
                </div>
                {this.renderKeywords()}
                <Grid style={{ marginTop: 20 }} container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <LabelN>Date</LabelN>
                    <InputWrapperN>
                      <DatePicker
                        data-test-id="handleDateChange"
                        selected={date}
                        onChange={this.handleDateChange}
                        dateFormat="dd-MM-yyyy"
                        placeholderText="Select date"
                        minDate={new Date()}
                        customInput={
                          <CustomInputN>
                            <this.InputMui
                              type="text"
                              readOnly
                              placeholder="Select date"
                              isError={!!dateError}
                              value={
                                date instanceof Date
                                  ? date?.toLocaleDateString()
                                  : ""
                              }
                            />
                            <IconWrapperN>
                              <img src={drop} alt="drop" />
                            </IconWrapperN>
                          </CustomInputN>
                        }
                      />
                    </InputWrapperN>
                    {dateError && <ErrorMsgN>{dateError}</ErrorMsgN>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelN>Time</LabelN>
                    <InputWrapperN>
                      <DatePicker
                        data-test-id="handleTimeChange"
                        selected={time}
                        onChange={this.handleTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        placeholderText="Select Time"
                        minTime={this.calculateMinTime(date)}
                        maxTime={new Date(0, 0, 0, 23, 59)}
                        customInput={
                          <CustomInputN>
                            <this.InputMui
                              type="text"
                              value={
                                time instanceof Date
                                  ? time.toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })
                                  : ""
                              }
                              placeholder="Select Time"
                              isError={!!timeError}
                            />
                            <IconWrapperN>
                              <img src={drop} alt="drop" />
                            </IconWrapperN>
                          </CustomInputN>
                        }
                      />
                    </InputWrapperN>
                    {timeError && <ErrorMsgN>{timeError}</ErrorMsgN>}
                  </Grid>
                </Grid>
                <div style={{ marginTop: 40 }}>
                  <LabelN>Images</LabelN>
                  <Grid container spacing={4} style={{ marginTop: 0 }}>
                    <Grid item xs={12} sm={4} md={4}>
                      <ContainerMainN>
                        {files?.map((file: any, index: any) => (
                          <div
                            className="map-divN"
                            key={index}
                            style={{ position: "relative" }}
                          >
                            <StyledImageN
                              src={URL.createObjectURL(file)}
                              className="map-imgN"
                              alt={`photo-${index}`}
                              data-test-id="handleOpenImageModal"
                            />
                            {index === files.length - 1 && (
                              <img
                                src={deleteIcon}
                                onClick={() => this.handleDeleteFile(index)}
                                alt="delete"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  width: "24px",
                                  height: "24px",
                                  cursor: "pointer",
                                  zIndex: 0,
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </ContainerMainN>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      <DropZoneN
                        onDrop={this.handleDrop}
                        onDragOver={this.handleDragOver}
                        data-test-id="handleDragOver"
                      >
                        <DropZoneChidN htmlFor="fileInput">
                          <img
                            style={{ cursor: "pointer" }}
                            src={uploadBrowse}
                            alt="uploadIcon"
                          />
                          <Title1N>Upload Image</Title1N>
                          <SubtitleN>Supported Format: JPG, PNG</SubtitleN>
                          <input
                            type="file"
                            multiple
                            style={{ display: "none" }}
                            onChange={this.handleFileSelect}
                            data-test-id="handleFileSelect"
                            id="fileInput"
                          />
                        </DropZoneChidN>
                      </DropZoneN>
                      {uploadError && (
                        <ErrorMsgN color="error">{uploadError}</ErrorMsgN>
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div style={webStyle.buttonContainer}>
                  <ButtonCancelN
                    variant="outlined"
                    data-test-id="handleCreateEventCancel"
                    onClick={this.handleCreateEventCancel}
                    style={{ marginRight: "1rem" }}
                  >
                    Cancel
                  </ButtonCancelN>
                  <ButtonDoneN
                    variant="contained"
                    data-test-id="handleCreateEventDone"
                    onClick={this.handleCreateEventDone}
                  >
                    Create
                  </ButtonDoneN>
                </div>
              </div>
            </ChildModalN>
          </ParentModalN>
        )}
      </>
    );
  }

  renderCarousel = () => {
    const { isExpanded, filteredEvents } = this.state;
    const {
      profile_image,
      name,
      event_date,
      images,
      description,
      event_name,
      is_public,
      current_user_like,
      like,
      comments,
      keywords,
    } = filteredEvents[0].attributes;
    const { id } = filteredEvents[0];
    return (
      <>
        <BackN src={back} onClick={this.handleViewEvent}></BackN>
        <HomeListMainViewN>
          <div style={webStyle.flex}>
            <HomeListImgN src={profile_image} />
            <HomeListInnerN>
              <HomeListNameN>
                {name}
                <img
                  src={is_public === "is_public" ? publicIcon : privateIcon}
                  alt="tag"
                />
              </HomeListNameN>
              <HomeListMutualN>{event_date}</HomeListMutualN>
            </HomeListInnerN>
          </div>
          <img
            style={{ cursor: is_public === "is_public" ? "pointer" : "" }}
            src={is_public === "is_public" ? rsvp : disabledRsvp}
            alt="RSVP button"
          />
        </HomeListMainViewN>
        <Carousel
          data-test-id="handleNext"
          animation="fade"
          autoPlay={false}
          swipe={true}
          index={this.state.index}
          indicators={false}
          navButtonsAlwaysVisible={true}
          next={this.handleNext}
          prev={this.handlePrevious}
          navButtonsProps={{
            style: webStyle.navButtonsProps,
          }}
        >
          {images.map((item: any) => (
            <Box key={item.id} style={webStyle.carousel}>
              <img
                style={webStyle.carouselImage}
                src={item.file_url}
                alt={`Slide ${item.id}`}
              />
            </Box>
          ))}
        </Carousel>
        <div style={{ margin: "24px 0px" }}>
          <ActionButtonN>
            <Likeapost2
              navigation={this.props.navigation}
              id={""}
              eventId={id}
              likeCount={like}
              currentUserLike={current_user_like}
              getAllDataCallBack={this.getEvents}
            />
            <Comments
              navigation={this.props.navigation}
              id={""}
              eventId={id}
              CommentCount={comments}
              getAllDataCallBack={this.getEvents}
            />
            <Share
              navigation={this.props.navigation}
              id={""}
              shareIcon={shareAction}
              eventId={id}
            />
            <ImgN src={flag} alt="Flag Icon" />
          </ActionButtonN>
        </div>
        <HomeListNameN>{event_name}</HomeListNameN>
        <FriendProfileMutualViewN>{keywords}</FriendProfileMutualViewN>
        <DescriptionViewN>
          {isExpanded ? description : `${description.slice(0, 200)}...`}
        </DescriptionViewN>
        {!isExpanded && description.length > 200 && (
          <this.ShowMoreButton
            onClick={this.toggleDescription}
            data-test-id="toggleDescription"
          >
            see more...
          </this.ShowMoreButton>
        )}
      </>
    );
  };

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { showCategorieCount, viewEvent, mapKey } = this.state;
    const isAlLCategoriesShown =
      showCategorieCount >= this.state.categories.length;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Header navigation={this.props.navigation} id={""} activeTab="5" />
        {viewEvent ? (
          <MainContainerViewN>{this.renderCarousel()}</MainContainerViewN>
        ) : (
          <>
            {mapKey && (
              <LoadScript
                googleMapsApiKey={this.state.mapKey}
                libraries={["places"]}
              >
                <MainContainerN>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={3}>
                      <SideMainContainerN>
                        <HeadingN>Select filter</HeadingN>
                        <DateMainN>
                          <img
                            src={calenderIcon}
                            alt="icon"
                            style={{ width: 24, height: 24 }}
                          />
                          <DatePicker
                            className="datepicker"
                            data-test-id="handleDateFilterChange"
                            selected={this.state.dateFilter}
                            onChange={this.handleDateFilterChange}
                            dateFormat="dd-MM-yyyy"
                            customInput={
                              <div>
                                <this.InputMuiDate
                                  type="text"
                                  readOnly
                                  placeholder="Select date"
                                  value={this.getFilteredDate(
                                    this.state.dateFilter
                                  )}
                                />
                              </div>
                            }
                          />
                          <button
                            style={webStyle.closeButton}
                            onClick={this.clearDateFilter}
                          >
                            ✕
                          </button>
                        </DateMainN>
                        {this.renderFilterSection(
                          "Categories",
                          this.state.categories,
                          this.state.showCategorieCount,
                          this.handleShowMoreCategories,
                          isAlLCategoriesShown
                        )}
                      </SideMainContainerN>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                      <div style={webStyle.flex}>
                        <EventTitleN>Events</EventTitleN>
                        <ButtonContainedN
                          variant="outlined"
                          onClick={this.handleCreateModal}
                          data-test-id="handleCreateModal"
                        >
                          <img src={add} style={{ paddingRight: 8 }} />
                          Create Event
                        </ButtonContainedN>
                      </div>
                      <SearchInputParentN>
                        <img
                          src={search}
                          alt="search"
                          style={{ paddingRight: 8 }}
                        />
                        <SearchInputN
                          type="search"
                          placeholder="Search events"
                          value={this.state.search}
                          onChange={this.handleSearch}
                          data-test-id="handleSearch"
                        ></SearchInputN>
                      </SearchInputParentN>
                      <GoogleMap
                        mapContainerStyle={webStyle.mapContainerStyle}
                        zoom={12}
                        center={this.state.center}
                        options={{
                          disableDefaultUI: true,
                          zoomControl: false,
                          streetViewControl: false,
                          mapTypeControl: false,
                          fullscreenControl: false,
                        }}
                      >
                        {this.state.eventList?.map((marker: any) => (
                          <>
                            <Marker
                              key={marker.id}
                              position={{
                                lat: marker.attributes.latitude,
                                lng: marker.attributes.longitude,
                              }}
                              icon={markerIcon}
                              data-test-id="handleMainModal"
                            />
                            <OverlayView
                              key={`overlay-${marker.id}`}
                              position={{
                                lat: marker.attributes.latitude,
                                lng: marker.attributes.longitude,
                              }}
                              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            >
                              <MarkerNameN>
                                {marker.attributes.event_name}
                              </MarkerNameN>
                            </OverlayView>
                          </>
                        ))}
                      </GoogleMap>
                      <div style={{ maxHeight: 800, overflow: "auto" }}>
                        {this.state.eventList?.map((item: any, index: any) =>
                          this.renderEventCard(item, index)
                        )}
                        {this.state.dataNotFound && (
                          <DataNotFoundN>
                            {this.state.dataNotFound}
                          </DataNotFoundN>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  {this.renderCreateEventModal()}
                </MainContainerN>
              </LoadScript>
            )}
          </>
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle: { [key: string]: any } = {
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexPointer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  flexWrap: {
    display: "flex",
    flexWrap: "wrap",
    gap: 8,
    marginTop: 16,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 40,
  },
  container: {
    maxWidth: "100%",
    padding: 0,
    height: 500,
    overflow: "hidden",
  },
  carousel: {
    flex: 1,
    maxHeight: 500,
    overflow: "hidden",
    borderRadius: 8,
  },
  carouselImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  navButtonsProps: {
    backgroundColor: "#fff",
    color: "#000",
    width: "24px",
    height: "24px",
    margin: "0px 27px",
  },
  mapContainerStyle: {
    width: "100%",
    height: "100%",
    maxHeight: 450,
    borderRadius: 24,
    marginTop: 24,
  },
  tagButton: {
    marginLeft: 8,
    background: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: 16,
  },
  closeButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: 16,
  },
};

const MainContainerN = styled("div")({
  margin: "62px 100px 10px 100px",
});

const MainContainerViewN = styled("div")({
  margin: "30px 100px 30px 100px",
});

const SideMainContainerN = styled("div")({
  padding: "8px 24px 24px 24px",
  boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
  "& .datepicker input::placeholder": {
    color: customTheme.palette.black.secondary,
    opacity: 1,
  },
});

const HeadingN = styled("div")({
  color: customTheme.palette.black.secondary,
  ...CustomFontVariant.palette.font18700,
});

const ButtonContainedN = styled(Button)({
  padding: "10px 20px",
  ...CustomFontVariant.palette.font16700,
  textTransform: "none",
  border: "none",
  margin: "30px 0px",
  backgroundColor: customTheme.palette.grey.primary,
});

const HeadingFilterN = styled("div")({
  color: customTheme.palette.black.secondary,
  ...CustomFontVariant.palette.font18700,
  marginBottom: 16,
});

const CheckboxTitleN = styled("div")({
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font16400,
});

const LocationListingN = styled("div")({
  display: "flex",
  alignItems: "center",
});

const EventTitleN = styled("div")({
  color: customTheme.palette.black.secondary,
  ...CustomFontVariant.palette.font24700,
});

const TitleN = styled("div")({
  color: customTheme.palette.black.secondary,
  ...CustomFontVariant.palette.font20700,
  marginBottom: 8,
});

const EventTitleMainN = styled("div")({
  color: customTheme.palette.black.primary,
  ...CustomFontVariant.palette.font20700,
});

const DescriptionN = styled("div")({
  color: customTheme.palette.black.secondary,
  ...CustomFontVariant.palette.font18400,
  marginBottom: 24,
});

const DescriptionViewN = styled("div")({
  color: customTheme.palette.grey.secondary,
  ...CustomFontVariant.palette.font16400,
  margin: "16px 0px 8px 0px",
});

const SearchInputParentN = styled("div")({
  borderRadius: 8,
  border: `1px solid ${customTheme.palette.grey.light}`,
  display: "flex",
  alignItems: "center",
  padding: 8,
});

const SearchInputN = styled("input")({
  border: "none",
  width: "100%",
  "&:focus": {
    outline: "none",
  },
  ...CustomFontVariant.palette.font16400,
});

const HomeListMainN = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 12,
  paddingInline: 20,
});

const HomeListMainViewN = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 12,
});

const HomeListNameN = styled("div")({
  ...CustomFontVariant.palette.font16700,
  display: "flex",
  alignItems: "center",
  gap: 8,
});

const HomeListMutualN = styled("div")({
  ...CustomFontVariant.palette.font14400,
  color: customTheme.palette.grey.main,
});

const HomeListImgN = styled(Avatar)({
  width: 56,
  height: 56,
});

const HomeListInnerN = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  paddingLeft: 10,
});

const DividerN = styled("div")({
  borderBottom: `1px solid${customTheme.palette.grey.coolGrey}`,
});

const EventCartN = styled("div")({
  borderRadius: 24,
  margin: "24px 0px",
  boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
});

const ActionButtonN = styled("div")({
  display: "flex",
  gap: 16,
});

const ImgN = styled("img")({
  cursor: "pointer",
});

const LabelN = styled("label")({
  ...CustomFontVariant.palette.font14700,
  color: customTheme.palette.grey.secondary,
});

const InputN = styled("input")(({ isError }: any) => ({
  marginTop: 8,
  height: 56,
  borderRadius: 8,
  padding: 10,
  width: "100%",
  borderColor: isError
    ? customTheme.palette.red.secondary
    : customTheme.palette.grey.light,
  borderWidth: 1,
  borderStyle: "solid",
  "&:focus": {
    outline: "none",
  },
}));

const TextAreaN = styled("textarea")(({ isError }: any) => ({
  marginTop: 8,
  height: 121,
  borderRadius: 8,
  padding: 10,
  width: "100%",
  borderColor: isError
    ? customTheme.palette.red.secondary
    : customTheme.palette.grey.light,
  borderWidth: 1,
  borderStyle: "solid",
  "&:focus": {
    outline: "none",
  },
}));

const ErrorMsgN = styled(Typography)({
  ...CustomFontVariant.palette.font12400,
  color: customTheme.palette.red.primary,
  marginTop: 2,
});

const WordCountN = styled("div")(({ descriptionError }: any) => ({
  display: "flex",
  justifyContent: descriptionError ? "space-between" : "end",
  ...CustomFontVariant.palette.font12400,
  color: customTheme.palette.grey.secondary,
}));

const InputWrapperN = styled("div")({
  position: "relative",
  width: "100%",
  "& > :first-child": {
    width: "100%",
  },
});

const IconWrapperN = styled("div")({
  position: "absolute",
  right: 12,
  top: 36,
  transform: "translateY(-50%)",
  display: "flex",
  alignItems: "center",
});

const CustomInputN = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "100%",
  "&:hover": {
    cursor: "pointer",
  },
});

const DropZoneN = styled("div")({
  width: "100%",
  height: "auto",
  maxHeight: 178,
  border: `2px dashed ${customTheme.palette.grey.light}`,
  borderRadius: 16,
  boxShadow: "0px 4px 8px 0px #00000008",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  aspectRatio: "4/3",
});

const DropZoneChidN = styled("label")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 16,
});

const ContainerMainN = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  columnGap: "9px",
  rowGap: "6px",
  width: "100%",
  height: "100%",
  position: "relative",
  maxHeight: 186,
  "& .map-divN": {
    height: 89.5,
  },
  "& .map-imgN": {
    position: "relative",
  },
  "& div:last-child .map-imgN": {
    filter: "brightness(0.5)",
    position: "relative",
    "&::after": {
      content: '""',
      display: "block",
      width: "24px",
      height: "24px",
      position: "absolute",
      top: "8px",
      right: "8px",
      backgroundImage: `url(${deleteIcon})`,
      backgroundSize: "cover",
      cursor: "pointer",
    },
  },
});

const Title1N = styled("div")({
  ...CustomFontVariant.palette.font16400,
  color: customTheme.palette.grey.extraLight,
});

const SubtitleN = styled("div")({
  ...CustomFontVariant.palette.font12400,
  color: customTheme.palette.grey.extraLight,
});

const StyledImageN = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const ParentModalN = styled(Box)({
  position: "fixed",
  background: "#00000080",
  top: 0,
  bottom: 50,
  left: 0,
  right: 0,
  width: "100%",
  height: "100%",
  zIndex: 999,
  overflow: "auto",
});

const ChildModalN = styled(Box)({
  width: "100%",
  maxWidth: 820,
  zIndex: 99999,
  background: customTheme.palette.white.main,
  padding: "36px 24px",
  borderRadius: "20px",
  margin: "150px auto",
  position: "relative",
});

const ButtonDoneN = styled(Button)({
  maxWidth: 201,
  width: "100%",
  minHeight: 56,
  backgroundColor: `${customTheme.palette.grey.primary}!important`,
  color: `${customTheme.palette.black.primary}!important`,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none !important",
});

const ButtonCancelN = styled(Button)({
  maxWidth: 201,
  width: "100%",
  minHeight: 56,
  color: `${customTheme.palette.black.primary}!important`,
  ...CustomFontVariant.palette.font16700,
  textTransform: "none !important",
  borderColor: `${customTheme.palette.black.primary}!important`,
});

const BackN = styled("img")({
  marginBottom: 20,
  cursor: "pointer",
});

const MarkerNameN = styled("div")({
  color: customTheme.palette.grey.secondary,
  ...CustomFontVariant.palette.font14700,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const MainDivN = styled("div")({
  margin: "0px 0px 40px 0px",
});

const TermErrorN = styled(Box)({
  height: 60,
  width: "100%",
  backgroundColor: customTheme.palette.red.light,
  borderLeft: `4px solid ${customTheme.palette.red.primary}`,
  marginTop: 40,
  borderRadius: 4,
});

const ErrorTypoN = styled(Typography)({
  color: customTheme.palette.red.primary,
  ...CustomFontVariant.palette.font12400,
  padding: "20px 16px",
  maxWidth: 328,
});

const DataNotFoundN = styled("div")({
  margin: 40,
  display: "flex",
  justifyContent: "center",
  color: customTheme.palette.grey.secondary,
  ...CustomFontVariant.palette.font20700,
});

const DateMainN = styled("div")({
  border: `1px solid ${customTheme.palette.black.primary}`,
  borderRadius: 8,
  margin: "30px 0px",
  display: "flex",
  padding: 10,
  position: "relative",
  alignItems: "center",
});

const TypographyItemN = styled(Typography)({
  ...CustomFontVariant.palette.font16400,
  color: customTheme.palette.grey.bold,
});

const FriendProfileMutualN = styled("div")({
  ...CustomFontVariant.palette.font14700,
  color: customTheme.palette.grey.main,
  marginBottom: 16,
});

const FriendProfileMutualViewN = styled("div")({
  ...CustomFontVariant.palette.font14700,
  color: customTheme.palette.grey.main,
  marginTop: 8,
});

const CustomFlagDropdownN = styled(Box)(({ isOpen }: any) => ({
  position: "relative",
  marginTop: 4,

  "& input": {
    width: "100%",
    border: `1px solid ${customTheme.palette.grey.light}`,
    height: 44,
    padding: "10px 28px 10px 8px",
    borderRadius: 8,
    "&:focus-visible": {
      border: `1px solid ${customTheme.palette.grey.light}`,
      outline: "none",
    },
  },
  "& .downIconN": {
    position: "absolute",
    top: 15,
    right: 10,
    color: "grey",
  },
  "& .flagItemN": {
    position: "absolute",
    left: 0,
    top: 44,
    borderRadius: 8,
    boxShadow: "0px 2px 4px 0px #00000026",
    background: customTheme.palette.white.main,
    display: isOpen ? "block" : "none",
    width: "100%",
    maxWidth: 452,
    padding: 10,
    zIndex: 9999999,
    "& p:hover": {
      backgroundColor: customTheme.palette.grey.primary,
    },
    "& p": {
      borderRadius: 8,
      fontSize: 16,
      padding: "8px 16px",
      cursor: "pointer",
    },
    overflowY: "auto",
    maxHeight: "30vh",
  },
}));

// Customizable Area End
