export const insertPhoto = require("../assets/insert_photo.png");
export const backButton = require("../assets/backButton.png");
export const profileImg = require("../assets/profileImg.png");
export const media = require("../assets/media.png");
export const group = require("../assets/group.png");
export const createNewGroupIcon = require("../assets/createNewGroup.svg");
export const searchIcon = require("../assets/searchIcon.svg");
export const groupImg = require("../assets/group_image.png");
export const uploadIcon = require("../assets/uploadIcon.svg");
export const checkMark = require("../assets/checkMark.svg");
export const groupMember = require("../assets/avatar.jpg");
export const moreVert = require("../assets/Vector.png");
