Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';
exports.deleteApiMethod = 'DELETE';
exports.patchApiMethod = 'PATCH';

exports.getChatListApiEndPoint = 'chat/chats';
exports.showChatApiEndPoint = 'bx_block_chat/chat_rooms';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'bx_block_chat/chat_rooms';
exports.updateReadMessageApiEndPoint = 'bx_block_chat/chat_rooms/update_read';
exports.updateChatApiEndPoint = 'chat/chats';
exports.getSearchFriendsListApiEndPoint = '/bx_block_chat/chat_rooms/search_friends_and_groups';
exports.getGroupsListApiEndpoint = '/bx_block_chat/group_chats';
exports.createNewGroup = '/bx_block_chat/group_chats';
exports.getSearchFriendsListForGroupApiEndPoint = '/bx_block_addfriends/connections';
exports.addMembersToGroupApiEndPoint = '/bx_block_chat/group_chats/add_accounts';
exports.getReportedReasonsApiEndpoint = '/bx_block_contentflag/contents/get_flag_category';
exports.createChatReportApiEndpoint = '/bx_block_contentflag/contents';
exports.leaveGroupChatApiEndPoint = '/bx_block_chat/group_chats/leave_group';
exports.makeGroupAdminApiEndPoint = '/bx_block_chat/group_chats/make_admin';
exports.blockFriendApiEndPoint = '/bx_block_block_users/block_users';
exports.removeMembersToGroupApiEndPoint = '/bx_block_chat/group_chats/remove_account';

exports.getMyChatsApiEndpoint = 'bx_block_chat/chat_rooms';

exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.unreadMessageText = "Unread Messages";
exports.lastMessageText = "Last Message:";
exports.writeMessagePlaceholder = "Write message here";
exports.usersText = "Users:";
exports.accountIdPlaceholder = "Account ID";
exports.namePlaceholder = "Name";
  // Customizable Area End