import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ChangeEvent } from "react";
import { Keyboard, ScrollView } from "react-native";
import DocumentPicker from "react-native-document-picker";
import {
  InputProps
} from "@mui/material";
import { getStorageData } from "../../../framework/src/Utilities";
import { EmojiClickData } from 'emoji-picker-react';
import firebase from '../../../web/firebase'
import { GroupFormData, ISearchFriendsListForGroup, GroupMember, IChat, IGroupChatAttributes } from "./ChatController";

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IMessage {
  id: string;
  text: string;
  sender: {
    id: number;
    name: string;
    photo?: string;
  };
  receiver?: {
    id: number;
    name: string;
  };
  isRead: boolean;
  createdAt: number;
  attachmentUrls?: string[];
}

export interface IChatSendMessage {
  receiver_id?: number; 
  group_id?: number;
  message: string;
  attachments?: { filename: string; attachment: string; }[];
};

export interface IAttachments {
  imageUrl: string,
  fileName: string,
  fileSize: number,
  docRes: Blob
}

export interface IReportObject {
  id: number,
  reason: string,
  created_at: string,
  updated_at: string
}

export interface IMutualGroups {
  id: string;
  type: string;
  attributes: IGroupChatAttributes;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  senderChatUrl: string | undefined;
  receiverId: number | null;
  accountId: number | null;
  senderName: string | null;
  receiverName: string | null;
  receiverPhoto: string | null;
  lastMessage: string | null;
  updateChatList: () => void;
  chatType: "private" | "group";
  groupMembersCount: number;
  isCreateGroupViewOpen: boolean;
  handleCloseCreateGroupView: () => void;
  groupFormData: GroupFormData;
  handleGroupFormChange: (fieldName: string, value: string) => void;
  searchFriendsListForGroup: ISearchFriendsListForGroup[];
  searchFriendForGroupInput: string;
  handleSearchFriendsForGroupInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  addMembersToTheGroup: (member: number) => void;
  handleCreateGroupChat: () => void;
  viewContact:boolean;
  viewContactCallback:()=>void;
  selectedChatId?: string | null;
  isGroupInfoVisible: boolean;
  viewGroupCallback: () => void;
  groupDescription: string;
  selectedGroupMember: GroupMember[];
  handleChatSelection: (chat: IChat, isViewGroup: boolean) => void;
  getChatList: () => void;
  receiverBio: string | null;
  receiverEmail: string | null;
  mutualGroups:  IMutualGroups[];
  isBlocked: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  chatId: number;
  message: string;
  accountIdInput: string;
  accountId: number;
  chatData: IMessage[] | null;
  isVisibleModal: boolean;
  isVisiblePreviewModal: boolean;
  imageUrl: string;
  fileName: string;
  docRes: unknown;
  isEmojiPickerOpen: boolean;
  keyboardHeight: number;
  muted: boolean | null;
  anchorElCoverOptions: any;
  openChatOptions: boolean;
  showDataCount: number;
  unfriend: boolean;
  blockAccount: boolean;
  reportModalOpen: boolean;
  openReportMenu: boolean;
  reportReason: string;
  blockToastOpen: boolean;
  blockToastMsg: string;
  reasonError: string;
  profileImage: string;
  attachments: IAttachments[];
  messageError: string;
  chatRefs: firebase.database.Reference[];
  anchorElGroupOptions: any;
  openGroupOptions: boolean;
  makeAdmin: boolean;
  removeMember: boolean;
  addMemberModalOpen: boolean;
  groupAttachments: string[],
  reportData: IReportObject[],
  reportId: number,
  leaveGroup: boolean,
  selectedGroupMemberId: number
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatApiCallId: string = "";
  getReportReasonApiCallId: string = "";
  addUserToChatApiCallId: string = "";
  leaveChatApiCallId: string = "";
  sendMessageApiCallId: string = "";
  toggleMuteApiCallId: string = "";
  addMemberToGroupApiCallId: string = "";
  updateReadMessageApiCallId: string = "";
  createChatReportApiCallId: string = "";
  updateGroupAdminApiCallId: string = "";
  blockFriendApiCallId: string = "";
  unfriendApiCallId: string = "";
  removeMemberToGroupApiCallId: string = "";
  refreshChatInterval: unknown;
  //(this.isPlatformWeb() ? number: ReturnType<typeof setInterval>)
  //((this.isPlatformWeb()) ? (number) : (NodeJS.Timer));
  scrollViewRef: React.RefObject<ScrollView>;
  fileInputRef: React.RefObject<InputProps & { click: Function }>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      chatId: -1,
      message: "",
      accountId: -1,
      accountIdInput: "",
      chatData: null,
      isVisibleModal: false,
      isVisiblePreviewModal: false,
      imageUrl: "",
      fileName: "",
      docRes: null,
      isEmojiPickerOpen: false,
      keyboardHeight: 0,
      muted: null,
      anchorElCoverOptions: null,
      openChatOptions: false,
      showDataCount: 10,
      unfriend: false,
      blockAccount: false,
      reportModalOpen: false,
      openReportMenu: false,
      reportReason: "",
      blockToastOpen: false,
      blockToastMsg: " Thank you. We have received your report.",
      reasonError: "",
      profileImage: "",
      attachments: [],
      messageError: "",
      chatRefs: [],
      anchorElGroupOptions: null,
      openGroupOptions: false,
      makeAdmin: false,
      removeMember: false,
      addMemberModalOpen: false,
      groupAttachments: [""], 
      reportData: [],
      reportId: 0,
      leaveGroup: false,
      selectedGroupMemberId: 0,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.scrollViewRef = React.createRef();
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    Keyboard.addListener("keyboardDidShow", this._keyboardDidShow.bind(this));
    Keyboard.addListener("keyboardDidHide", this._keyboardDidHide.bind(this));

    const profileImage = await getStorageData("profile");
    this.setState({ profileImage });
    this.getReportReason();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.senderChatUrl !== prevProps.senderChatUrl) {
      this.clearAllChatListeners();
      this.listenForMessages();
      if (this.props.lastMessage) {
        this.updateReadMessages();
      }
    }
    if(this.props.viewContact!==prevProps.viewContact){
      this.setState({
        openChatOptions: false,
        anchorElCoverOptions: null,
      })
    }

    if(this.props.isGroupInfoVisible!==prevProps.isGroupInfoVisible){
      this.setState({
        openChatOptions: false,
        anchorElCoverOptions: null,
        showDataCount: 7
      })
    }
  }

  async componentWillUnmount() {
    clearInterval(this.refreshChatInterval as number);
    this.clearAllChatListeners();
  }

  listenForMessages = () => {
    const { senderChatUrl } = this.props;
    if (!senderChatUrl) return;
  
    let starCountRef = firebase.database().ref(senderChatUrl);
    
    this.setState(prevState => ({
      chatRefs: [...prevState.chatRefs, starCountRef]
    }));
    
    starCountRef.on('value', (snapshot: any) => {
      const messages: IMessage[] = [];
      for (const messageId in snapshot.val()) {
        const messageData = snapshot.val()[messageId];
        
        const id = messageId;
        const text = messageData.text;
        const sender = {
          id: messageData.sender_details.account_id,
          name: messageData.sender_details.name,
          ...(this.props.chatType === "group" && {
            photo: messageData.sender_details.photo,
          }),
        };
        const isRead = messageData.is_read;
        const createdAt = messageData.createdAt;
        const attachmentUrls = messageData.attachment_urls || [];

        const message: IMessage = {
          id,
          text,
          sender,
          isRead,
          createdAt,
          attachmentUrls,
          ...(this.props.chatType === "private" && {
            receiver: {
              id: messageData.receiver_details.account_id,
              name: messageData.receiver_details.name,
            },
          }),
        };
        
        messages.push(message);
      }

      let attachments: string[] = [];
       messages.forEach((message: any) => {
        if (message.attachmentUrls.length > 0) {
          message.attachmentUrls.forEach((url: string) => (
            attachments.push(url)
          ))
        }
      });
      this.setState({ chatData: messages, groupAttachments: attachments});
    });
  };

  clearAllChatListeners = () => {
    this.state.chatRefs.forEach(ref => ref.off());
    this.setState({ chatRefs: [] });
  };

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return !string || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  hidePreviewModal = () => {
    this.setState({ isVisiblePreviewModal: false, imageUrl: '', docRes: null });
  };

  handleAccountIdInputChange = (accountIdInput: string) => {
    this.setState({ accountIdInput });
  };

  handleMessageChange = (message: string) => {
    this.setState({ message, messageError: "" });
  };

  sendMessageOnKeyEnter = (event: { key: string; }) => {
    if (event.key === 'Enter') {
      this.handleSendMessage();
    }
  }

  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0] as Blob;
      const fileName = files[0].name;

      if (file.type === 'image/svg+xml') {
        this.setState({
          imageUrl: "",
          fileName: "",
          docRes: null,
          attachments: [],
          isVisiblePreviewModal: false,
          messageError: "Allows only jpeg or png format images"
        });
        return;
      }
      
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        const imageUrl = readerEvent.target?.result as string;
  
        const newAttachment = {
          imageUrl,
          fileName,
          fileSize: file.size,
          docRes: file
        };
  
        this.setState((prevState) => ({
          imageUrl,
          docRes: file,
          fileName,
          isVisiblePreviewModal: true,
          attachments: [...prevState.attachments, newAttachment],
          messageError: ""
        }));
      };
  
      reader.readAsDataURL(file);
    } else {
      this.setState({
        imageUrl: "",
        fileName: "",
        docRes: null,
        attachments: [],
        isVisiblePreviewModal: false
      });
    }
  };

  removeAttachment = (index: number) => {
    this.setState((prevState) => ({
      attachments: prevState.attachments.filter((_, i) => i !== index)
    }));
  }

  handleChangeGroupAvatar = (e: any) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      
      reader.onloadend = () => {
        const base64Data = reader.result as string;
        this.props.handleGroupFormChange("selectedAvatarImage", base64Data);
      };

      reader.readAsDataURL(file);
    }
  };

  handleSendMessage = () => {
    const { message, attachments } = this.state;

    const trimmedMessage = message.trim();

    if (!trimmedMessage && attachments.length === 0) {
      this.setState({ messageError: "Enter a message to send" });
    } else {
      this.sendChatMessage(this.props.receiverId || 0, message, attachments);
  
      this.setState({
        message: "",
        isVisiblePreviewModal: false,
        attachments: [],
        isEmojiPickerOpen: false,
        messageError: ""
      });
  
      this.props.updateChatList();
    }
  };

  toggleEmojiPicker = () => {
    this.setState({ isEmojiPickerOpen: !this.state.isEmojiPickerOpen, messageError: "" });
  };

  handleEmojiSelect = (emojiObject: EmojiClickData) => {
    this.setState({
      message: this.state.message + emojiObject.emoji
    })
  };

  getChatDetails = (token: string, chatId: number) => {
    const header = {
      "Content-Type": "application/json",
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showChatApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getReportReason = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReportReasonApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getReportedReasonsApiEndpoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addUserToChat = (accountIdInput: string, chatId: number) => {
    if (!accountIdInput || this.isStringNullOrBlank(accountIdInput)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const bodyData = {
        accounts_id: [Number(accountIdInput)],
        chat_id: Number(chatId),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addUserToChatApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addUserToChatApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  addMemberToGroup = () => {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const bodyData = {
        id: this.props.selectedChatId,
        group: {
          group_chat_members_id: this.props.groupFormData.groupMembers,
        }
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addMemberToGroupApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addMembersToGroupApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removeMemberToGroup = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      "id": Number(this.props.selectedChatId),
      "group": {
          "group_chat_member_id": Number(this.state.selectedGroupMemberId)
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeMemberToGroupApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeMembersToGroupApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
};

  updateGroupAdmin = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      id: this.props.selectedChatId,
      group: {
        account_id: this.state.selectedGroupMemberId,
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateGroupAdminApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.makeGroupAdminApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
};

  leaveChatRoom = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      id: Number(this.props.receiverId),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.leaveChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.leaveGroupChatApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  blockFriend = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      data: {
        attributes: {
          account_id: this.props.receiverId,
        }
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.blockFriendApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockFriendApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
};

unfriendUser = () => {
  const header = {
    "Content-Type": configJSON.apiContentType,
    token: this.state.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.unfriendApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getSearchFriendsListForGroupApiEndPoint}/${this.props.receiverId}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.deleteApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

  sendChatMessage = async (chatId: number, message: string, attachments?: IAttachments[]) => {
    const token = this.state.token;
    const header = {
      "Content-Type": "application/json",
      token,
    };

    const messageData: IChatSendMessage = {
      message,
      ...(this.props.chatType === "private"
        ? { receiver_id: chatId }
        : { group_id: chatId })
    };

    if (attachments && attachments.length > 0) {
      messageData.attachments = attachments.map(attachment => ({
        filename: attachment.fileName,
        attachment: attachment.imageUrl.split(',')[1],
      }));
    this.setState({ docRes: null });
  }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendMessageApiEndPoint}/send_message`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(messageData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeNotificationStatus = () => {
    const { muted } = this.state;
    if (muted === null) {
      this.setState({ muted: true });
    } else {
      this.setState({ muted: !muted });
    }
    this.toggleMute();
  };

  toggleMute = () => {
    const { chatId, muted } = this.state;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat: { muted: !muted },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.toggleMuteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateChatApiEndPoint}/${chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateReadMessages = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const bodyData = {
      chat_location: this.props.senderChatUrl
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateReadMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateReadMessageApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openFile = async () => {
    try {
      const response = await DocumentPicker.pickSingle({
        type: [
          DocumentPicker.types.images,
        ],
      });

      if (response) {
        this.setState({ imageUrl: response.uri, isVisiblePreviewModal: true, docRes: response })
      }
    } catch (error) {
      if (DocumentPicker.isCancel(error)) {
        runEngine.debugLog("Message Recived", "User Canceled Picker");
      } else {
        runEngine.debugLog("Message Recived", error);
      }
    }
  };

  inputAccountIdProps = {
    onChangeText: (text: string) => {
      this.setState({ accountIdInput: text });
    },
  };

  inputMessageProps = {
    onChangeText: (text: string) => {
      this.setState({ message: text });
    },
  };

  btnAddAccountProps = {
    onPress: () =>
      this.addUserToChat(this.state.accountIdInput, this.state.chatId),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => this.showModal(),
  };

  btnLeaveChatProps = {
    onPress: () => this.leaveChatRoom(),
  };

  btnSendMessageProps = {
    onPress: () => {
      this.sendChatMessage(this.state.chatId, this.state.message, this.state.attachments);
      this.setState({ message: "", imageUrl: "", isVisiblePreviewModal: false });
    },
  };

  btnClosePreviewModal = {
    onPress: () => this.hidePreviewModal(),
  }

  btnMuteProps = {
    onPress: () => this.changeNotificationStatus(),
  };

  btnInsertPhotoProps = {
    onPress: () => this.openFile(),
  };

  _keyboardDidShow = (event: { endCoordinates: { height: number } }) => {
    this.setState({ keyboardHeight: event.endCoordinates.height });
  };

  _keyboardDidHide = (event: { endCoordinates: { height: number } }) => {
    this.setState({ keyboardHeight: 0 });
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);

    await this.handleSessionAndToken(message);

    this.handleNavigationMessages(message);

    switch (apiRequestCallId) {
      case this.leaveChatApiCallId:
        this.handleLeaveGroupChatResponce(responseJson);
        break;

      case this.createChatReportApiCallId:
        this.setState({reportModalOpen: false, reportReason: "", reportId: 0, blockToastOpen: true, blockToastMsg: responseJson.success[0].message})
        break;

      case this.blockFriendApiCallId: 
       this.setState({blockToastOpen: true, blockToastMsg: responseJson.meta.message, blockAccount: false})
       break;

      case this.unfriendApiCallId: 
      this.setState({blockToastOpen: true, blockToastMsg: responseJson.meta.message, unfriend: false})
      break;

      case this.removeMemberToGroupApiCallId: 
      this.setState({blockToastMsg: "Group member has been removed Successfully", blockToastOpen: true, removeMember: false})
      break;
    }

    this.handleApiResponses(apiRequestCallId, message, responseJson);
  }

  async handleSessionAndToken(message: Message) {
    const token = await getStorageData("token");
    if (token) this.setState({ token });
  
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("TOKEN", token);
      const messageData = JSON.parse(
        message.getData(getName(MessageEnum.SessionResponseData))
      );
      
      if (messageData && messageData.meta) {
        const accountId: number = messageData.meta.id;
        this.setState({ accountId });
      }
  
      this.refreshChatInterval = setInterval(
        () => this.getChatDetails(this.state.token, this.state.chatId),
        30000
      );
    }
  }

  handleNavigationMessages(message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const chatData = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
      const { chatId } = chatData;
      this.setState({ chatId }, () =>
        this.getChatDetails(this.state.token, chatId)
      );
    }
  }

  handleLeaveGroupChatResponce = (responseJson: {message: string}) => {
    this.props.getChatList()
    this.setState({leaveGroup: false, blockToastMsg: responseJson.message, blockToastOpen: true})
  }

  handleApiResponses(apiRequestCallId: string, message: Message, responseJson: any) {
    const restApiDataCondition: boolean =
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id;
  
    if (
      restApiDataCondition &&
      apiRequestCallId === this.getChatApiCallId &&
      responseJson && responseJson.data
    ) {
      const chatData = responseJson.data;
      const { muted } = chatData.attributes.accounts_chats.find(
        (item: { attributes: { account_id: number } }) =>
          item.attributes.account_id === this.state.accountId
      ).attributes;
      this.setState({ chatData, muted });
    }

    if (
      restApiDataCondition &&
      apiRequestCallId === this.addMemberToGroupApiCallId &&
      responseJson.data
    ) {
      this.props.handleChatSelection(responseJson.data, true)
      this.setState({addMemberModalOpen: false, blockToastOpen: true, blockToastMsg: "Members successfully added"})
    } 

    if (
      restApiDataCondition &&
      apiRequestCallId === this.updateGroupAdminApiCallId &&
      responseJson.data
    ) {
      this.props.handleChatSelection(responseJson.data, true)
      this.setState({makeAdmin: false, blockToastOpen: true, blockToastMsg: "Admin successfully updated"})
    } 

    if (
      restApiDataCondition &&
      apiRequestCallId === this.getReportReasonApiCallId &&
      responseJson.success
    ) {
      this.setState({reportData: responseJson.success[0].flag_catogory})
    } 
  
    if (restApiDataCondition && apiRequestCallId === this.sendMessageApiCallId) {
      this.getChatDetails(this.state.token, this.state.chatId);
    }
  
    if (restApiDataCondition && apiRequestCallId === this.addUserToChatApiCallId) {
      this.hideModal();
      this.getChatDetails(this.state.token, this.state.chatId);
    }
  }

handleClickOptions = (event: any) => {
  this.setState({
    anchorElCoverOptions: event.currentTarget,
    openChatOptions: true,
  });
};

handleClickOptionsClose = () => {
  this.setState({
    anchorElCoverOptions: null,
    openChatOptions: false,
  });
};

handleGroupInfoMenu = (event: any, item: any) => {
  this.setState({
    anchorElGroupOptions: event.currentTarget,
    openGroupOptions: true,
    selectedGroupMemberId: item.attributes.account_id
  });
};

handleGroupInfoMenuClose = () => {
  this.setState({
    anchorElGroupOptions: null,
    openGroupOptions: false,
  });
};

handleCloseOptions = () => {
  this.setState({ anchorElCoverOptions: null, openChatOptions: false });
};

handleShowMore = () => {
  const { showDataCount, groupAttachments } = this.state;
  if (showDataCount < groupAttachments.length) {
    this.setState({ showDataCount: showDataCount + 3 });
  }
};

handleUnfriendModal = () => {
  this.setState({ unfriend: !this.state.unfriend });
  this.handleClickOptionsClose();
};

handleBlockAccountModal = () => {
  this.setState({ blockAccount: !this.state.blockAccount });
  this.handleClickOptionsClose();
};

handleReportModal = () => {
  this.setState({
    reportModalOpen: !this.state.reportModalOpen,
    reportReason: "",
    reasonError: "",
  });
  this.handleClickOptionsClose();
};

handleReportModalDone = () => {
  const header = {
    "Content-Type": configJSON.apiContentType,
    token: this.state.token,
  };
  const bodyData = {
    "content":{
      "flag_category_id": this.state.reportId,
      "contentable_type": this.props.chatType === "private" ? "AccountBlock::Account" : 'BxBlockChat::GroupChat',
      "contentable_id": Number(this.props.receiverId)
      }
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.createChatReportApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.createChatReportApiEndpoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(bodyData)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.postApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);  
};

handleSelectReason = (reportReason: IReportObject) => {
  this.setState({ reportReason: reportReason.reason, reportId: reportReason.id, openReportMenu: false });
};

handleMenuItem = () => {
  this.setState({ openReportMenu: !this.state.openReportMenu, reasonError: ""});
};

handleGroupMedia = () => {
  this.handleClickOptionsClose();
}

handleChangeWallpaper = () => {
  this.handleClickOptionsClose();
}

handleExitChat = () => {
  this.handleClickOptionsClose();
}

handleAdmin = () => {
  this.handleGroupInfoMenuClose();
  this.setState({ makeAdmin: !this.state.makeAdmin });
  this.handleGroupInfoMenuClose();
};

handleRemove = () => {
  this.handleGroupInfoMenuClose();
  this.setState({ removeMember: !this.state.removeMember });
};

menuOptionsOptions = [
  { label: "View Contact", action: this.props.viewContactCallback },
  { label: "Unfriend", action: this.handleUnfriendModal },
  { label: "Block", action: this.handleBlockAccountModal },
  { label: "Report", action: this.handleReportModal },
];

menuOptionsForGroups = [
  { label: "Group Info", action: this.props.viewGroupCallback },
  { label: "Group Media", action: this.handleGroupMedia },
  { label: "Change Wallpaper", action: this.handleChangeWallpaper },
  { label: "Exit Chat", action: this.handleExitChat },
];

menuOptionsForGroupInfo = [
  { label: "Make member admin", action: this.handleAdmin },
  { label: "Remove", action: this.handleRemove },
];

handleCloseBlockToast = () => {
  this.setState({ blockToastOpen: false });
};

handleLeaveGroup = () => {
  this.setState({leaveGroup: !this.state.leaveGroup})
}
  // Customizable Area End
}
