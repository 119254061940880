export const AppLogo = require("../src/assets/app_logo.png");
export const Home = require("../src/assets/home.png");
export const Community = require("../src/assets/community.png");
export const About = require("../src/assets/aboutus.png");
export const setting = require("../src/assets/setting.png");
export const notification = require("../src/assets/notification.png");
export const message = require("../src/assets/message.png");
export const network = require("../src/assets/network.png");
export const profileMobileView = require("../src/assets/profile.png");
export const crossIcon = require("../src/assets/cross.png");
export const successIcon = require("../src/assets/success.png");
export const closeIcon = require("../src/assets/image_close.png");
export const errorIcon = require("../src/assets/Error.png");
